<template>
  <div class="gallery-container">
    <div class="slider">
      <div class="slides" :style="slideStyles">
        <div class="slide" v-for="(photo, index) in photos" :key="index">
          <div class="card">
            <img :src="photo" :alt="`Slide ${index}`" />
            <img :src="photo" :alt="`Slide ${index}`" />
          </div>
        </div>
      </div>
    </div>
    <button class="prev" @click="prev"><</button>
    <button class="next" @click="next">></button>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

import room1 from "@/assets/rooms/room1.jpg";
import room2 from "@/assets/rooms/room2.jpg";
import room3 from "@/assets/rooms/room3.jpg";
import room4 from "@/assets/rooms/room4.jpg";
import room5 from "@/assets/rooms/room5.jpg";
import room6 from "@/assets/rooms/room6.jpg";
import room7 from "@/assets/rooms/room7.jpg";

const currentSlideIndex = ref(0);
const photos = ref([room1, room2, room3, room4, room5, room6, room7]);

const slideStyles = computed(() => {
  return {
    transform: `translateX(-${
      currentSlideIndex.value * (150 / photos.value.length)
    }%)`,
    transition: "transform 0.3s ease-in-out",
    display: "flex",
  };
});

const next = () => {
  if (currentSlideIndex.value < 2) {
    currentSlideIndex.value++;
  } else {
    currentSlideIndex.value = 0; // Loop back to the first image
  }
};

const prev = () => {
  if (currentSlideIndex.value > 0) {
    currentSlideIndex.value--;
  } else {
    currentSlideIndex.value = 2; // Loop to the last image
  }
};

document.body.onmousemove = (e) => {
  document.body.style.setProperty("--mouse-x", e.clientX);
  document.body.style.setProperty("--mouse-y", e.clientY);
};
</script>

<style scoped>
.gallery-container {
  position: relative;
  max-width: 1800px;
  margin: auto;
  overflow: hidden;
  /* background: #e3e3e3; */
  padding: 3em;
}

.slider {
  display: flex;
}

.slides {
  display: flex;
  transition: transform 0.1s ease-in-out;
  gap: 40px;
}

.slide {
  /* flex: 0 0 33%; */
  max-width: 33%;
}

img {
  width: 100%;
  display: block;
}

.card {
  --grad-size: min(270vh, 90vw);
  width: 100%;
  aspect-ratio: 1;
  background-image: radial-gradient(#fff 0%, #555 30%, #222 50%);
  background-color: #222;
  background-position: calc(var(--mouse-x) * 1px - var(--grad-size) / 2)
    calc(var(--mouse-y) * 1px - var(--grad-size) / 2);
  transition: background-position 2s cubic-bezier(0, 0, 0, 1);
  background-attachment: fixed;
  background-size: var(--grad-size) var(--grad-size);
  background-repeat: no-repeat;
  overflow: hidden;
  cursor: pointer;
}

.card::before {
  content: "";
  width: min(90vh, 30vw);
  aspect-ratio: 1;
  position: absolute;
  background-size: 300% 300%;
  background-position: 100% 100%;
  transition: background-position 0.3s linear;
}

.card:hover::before {
  background-position: 0% 0%;
}

.card > img {
  width: min(90vh, 30vw);
  aspect-ratio: 1;
  object-fit: cover;
}

.card > :first-child {
  mix-blend-mode: multiply;
  position: absolute;
  filter: blur(min(3vh, 1vw)) brightness(0%);
  transition: filter 0.5s ease;
}

.card:hover > :first-child {
  filter: blur(min(4.5vh, 1.5vw)) brightness(100%);
}

.card > :nth-child(2) {
  mix-blend-mode: plus-lighter;
}

button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
</style>
