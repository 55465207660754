<template>
  <footer>
    <nav class="navbar">
      <a
        data-testid="linkElement"
        href="https://www.facebook.com/Grace-Bay-Villa-Rentals-106791370727720/?__tn__=%2Cd%2CP-R&amp;eid=ARC6HpOeoURg_kWwu-973szTfFFz3LOfN2wSpeLEvG0OxI6YNy0Ahz3S_psf6hr_s9b1cOjMBDaivcRc"
        target="_blank"
        class="oRtuWN"
        aria-label="White Facebook Icon"
        ><wow-image
          id="img_0_comp-jz1isdzc"
          class="HlRz5e YaS0jR"
          data-image-info='{"containerId":"dataItem-k0xzdac4-comp-jz1isdzc","displayMode":"fill","imageData":{"width":200,"height":200,"uri":"23fd2a2be53141ed810f4d3dcdcd01fa.png","name":"","displayMode":"fill"}}'
          data-bg-effect-name=""
          data-has-ssr-src=""
          style="--wix-img-max-width: max(200px, 100%)"
          data-src="https://static.wixstatic.com/media/23fd2a2be53141ed810f4d3dcdcd01fa.png/v1/fill/w_56,h_56,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/23fd2a2be53141ed810f4d3dcdcd01fa.png"
          ><img
            alt="White Facebook Icon"
            fetchpriority="high"
            src="https://static.wixstatic.com/media/23fd2a2be53141ed810f4d3dcdcd01fa.png/v1/fill/w_56,h_56,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/23fd2a2be53141ed810f4d3dcdcd01fa.png"
            style="width: 28px; height: 28px; object-fit: cover" /></wow-image
      ></a>
      <a
        data-testid="linkElement"
        href="https://www.instagram.com/impulsebeachestate/"
        target="_blank"
        class="oRtuWN"
        aria-label="White Instagram Icon"
        ><wow-image
          id="img_1_comp-jz1isdzc"
          class="HlRz5e YaS0jR"
          data-image-info='{"containerId":"dataItem-jz1ise0p2-comp-jz1isdzc","displayMode":"fill","imageData":{"width":200,"height":200,"uri":"81af6121f84c41a5b4391d7d37fce12a.png","name":"","displayMode":"fill"}}'
          data-bg-effect-name=""
          data-has-ssr-src=""
          style="--wix-img-max-width: max(200px, 100%)"
          data-src="https://static.wixstatic.com/media/81af6121f84c41a5b4391d7d37fce12a.png/v1/fill/w_56,h_56,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/81af6121f84c41a5b4391d7d37fce12a.png"
          ><img
            alt="White Instagram Icon"
            fetchpriority="high"
            src="https://static.wixstatic.com/media/81af6121f84c41a5b4391d7d37fce12a.png/v1/fill/w_56,h_56,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/81af6121f84c41a5b4391d7d37fce12a.png"
            style="width: 28px; height: 28px; object-fit: cover" /></wow-image
      ></a>
      <a class="nav-item" href="https://maps.app.goo.gl/u81cneh1b1VrTs9XA"
        >06 Coconut Rd Turtle Cove, TKCA 1ZZ Providenciales.</a
      >
      <a class="nav-item" href="tel:+14162777633">+1-561-562-9814</a>
      <a class="nav-item" href="mailto:info@gracebayvillarentals.com"
        >info@gracebayvillarentals.com</a
      >
    </nav>
  </footer>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

footer {
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 0.5rem 5vw;
  z-index: 5;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.nav-item {
  position: relative;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.13px;
  text-decoration: none;
  margin-left: 2.5rem;
  transition: all 0.5s ease;
}

.nav-item:hover {
  color: rgb(168, 239, 255, 0.9);
}

.nav-item::after {
  content: "";
  position: absolute;
  bottom: -0.3rem;
  left: 50%;
  width: 0;
  height: 0.15rem;
  transform: translateX(-50%);
  background-color: rgb(168, 239, 255, 0.9);
  transition: all 0.5s ease;
}

.nav-item:hover::after {
  width: 100%;
}

.icons {
  position: absolute;
  right: 5%;
  font-size: 2.3rem;
  color: #fff;
  cursor: pointer;
  display: none;
}

#check {
  display: none;
}

@media (max-width: 900px) {
  header {
    padding: 1.3rem 5%;
  }

  .content h1 {
    font-size: 7rem;
  }
}

@media (max-width: 700px) {
  header::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(50px);
    z-index: -1;
  }

  .icons {
    display: inline-flex;
  }

  #check:checked ~ .icons #menu-icon {
    display: none;
  }

  .icons #close-icon {
    display: none;
  }

  #check:checked ~ .icons #close-icon {
    display: block;
  }

  .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(50px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
  }

  #check:checked ~ .navbar {
    height: 17.7rem;
  }

  .nav-item {
    display: block;
    font-size: 1.1rem;
    margin: 2rem 0;
    text-align: center;
    transform: translateY(-50px);
    opacity: 0;
    transition: all 0.3s ease;
  }

  .nav-item:hover::after {
    width: auto;
  }

  #check:checked ~ .navbar a {
    transform: translateY(0);
    opacity: 1;
    transition-delay: calc(0.15s * var(--i));
  }
}

@media (max-width: 620px) {
  .content h1 {
    font-size: 5rem;
  }
}

@media (max-width: 450px) {
  .content h1 {
    font-size: 3rem;
  }

  .btn {
    width: 130px;
  }

  .light::after {
    width: 120px;
    font-size: 0.9rem;
  }
}
</style>
