<template>
  <div class="home">
    <div class="background"></div>
    <div class="main">
      <img
        src="@/assets/beach.png"
        alt="Beach background"
        class="background-video"
      />
      <section class="content">
        <h1>Impulse</h1>
        <h2>Boutique Hotel</h2>
        <router-link class="btn light" to="/inquiry" />
      </section>
    </div>
    <div class="container">
      <section class="estate-description row">
        <div class="border">
          <div class="card">
            <img src="@/assets/beach.jpg" />
            <img src="@/assets/beach.jpg" />
          </div>
        </div>

        <div class="col">
          <h1>Impulse Beach Estate</h1>
          <p>
            Impulse is a Beach Estate located on the award-winning GraceBayBeach
            of Providenciales Turks and Caicos Islands.
          </p>
          <p>
            This boutique hotel style estate is located on prime beachfront of
            smith’s reef national park with close access to nearby amenities.
          </p>
          <p>
            Experience a collective community experience of Turtle Cove with a
            tennis court on property, kayaks and paddle boards for ocean
            explorative groups, and a large open kitchen.
          </p>
          <p>
            <router-link class="link" to="/amenities"
              >Interested in viewing all amenities?</router-link
            >
          </p>
        </div>
      </section>

      <section class="rooms-facilities row">
        <div class="col">
          <h2>Rooms & Facilities</h2>
          <p>
            There are 8 rooms on property, each containing a private washer and
            dryer alongside their private bathrooms.
          </p>
          <p>
            The property features an additional 4 rest rooms in public areas,
            with showers near the poolside, beach, and canal are readily
            available for use.
          </p>
          <p>
            We have a custom tailored bar, cinema, and rooftop for lounging and
            socializing.
          </p>
          <p>
            <a href="#rooms" class="link">Interested in viewing all rooms?</a>
          </p>
        </div>

        <div class="border">
          <div class="card">
            <img src="@/assets/conservatory.jpg" />
            <img src="@/assets/conservatory.jpg" />
          </div>
        </div>
      </section>

      <section class="additional-servicesn row">
        <div class="border">
          <div class="card">
            <img src="@/assets/buggy.jpg" />
            <img src="@/assets/buggy.jpg" />
          </div>
        </div>

        <div class="col">
          <h2>Additional Services</h2>
          <p>
            As innovators within TCI’s hotel consortium, we strongly appreciate
            individuals who take care for attention in details.
          </p>
          <p>
            We have meticulously handcrafted an experience to provide onsite
            catering, butler, and maid service upon request, at any time for
            your convenience.
          </p>
          <p>
            Impulse is the ideal location for corporate events, and individuals
            seeking to book together for a retreat. With 21,500 Sq.ft. of
            property, there are common areas for professionals and families
            alike to book the property.
          </p>
          <p>
            <router-link class="link" to="/amenities"
              >Looking for more services?
            </router-link>
          </p>
        </div>
      </section>
    </div>
    <Gallery id="rooms" />

    <div class="map">
      <footer>
        <router-link class="btn light" to="/inquiry" />
        <p>
          We are available for inquiries and concerns Call:
          <a href="tel:+14162777633">+1-561-562-9814</a> or email
          <a href="mailto:info@gracebayvillarentals.com"
            >info@gracebayvillarentals.com</a
          >
        </p>
      </footer>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3704.863754259143!2d-72.22463352443178!3d21.785533461356327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x894b4f90f5541549%3A0x529ce76b7daab355!2sGrace%20Bay%20Villa%20Rentals!5e0!3m2!1sru!2sru!4v1715190511083!5m2!1sru!2sru"
        width="100%"
        height="600"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</template>

<script setup>
import Gallery from "@/components/Gallery.vue";

document.body.onmousemove = (e) => {
  document.body.style.setProperty("--mouse-x", e.clientX);
  document.body.style.setProperty("--mouse-y", e.clientY);
};
</script>

<style lang="scss">
.home {
  margin-bottom: 45px;
  color: #133e6c;
  font-size: 18px;
}
footer {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3em;
  gap: 0.5em;
  text-align: center;
  a {
    color: #133e6c;
  }
  .btn {
    background: #133e6c;
  }
}
.background {
  position: fixed;
  background: url("/src/assets/palm.png") no-repeat;
  background-position: center;
  background-size: 150%;
  backdrop-filter: blur(100px);
  opacity: 0.2;
  width: 100%;
  height: 100vh;
  z-index: -1;
}
.container {
  margin: auto;
  padding: 3em;
  width: 90vw;
  max-width: 1500px;
}

.link {
  position: relative;
  color: #133e6c;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.13px;
  text-decoration: none;
  transition: all 0.5s ease;
}

.link:hover {
  color: rgb(168, 239, 255, 0.9);
}

.link::after {
  content: "";
  position: absolute;
  bottom: -0.3rem;
  left: 50%;
  width: 0;
  height: 0.15rem;
  transform: translateX(-50%);
  background-color: rgb(168, 239, 255, 0.9);
  transition: all 0.5s ease;
}

.link:hover::after {
  width: 100%;
}
.row {
  display: flex;
  gap: 60px;
  margin: 60px 0;
  align-items: center;
}

.border {
  padding: 14px;
  border: 2px solid rgb(168, 239, 255, 1);
  outline: 2px solid rgb(168, 239, 255, 1);
  outline-offset: -10px;
}

.img {
  width: 100%;
  display: block;
}
.card {
  --grad-size: min(270vh, 90vw);
  width: 100%;
  aspect-ratio: 1;
  background-image: radial-gradient(#fff 0%, #555 30%, #222 50%);
  background-color: #222;
  background-position: calc(var(--mouse-x) * 1px - var(--grad-size) / 2)
    calc(var(--mouse-y) * 1px - var(--grad-size) / 2);
  transition: background-position 2s cubic-bezier(0, 0, 0, 1);
  background-attachment: fixed;
  background-size: var(--grad-size) var(--grad-size);
  background-repeat: no-repeat;
  overflow: hidden;
  cursor: pointer;
}

.card::before {
  content: "";
  width: min(90vh, 30vw);
  aspect-ratio: 1;
  position: absolute;
  background-size: 300% 300%;
  background-position: 100% 100%;
  transition: background-position 0.3s linear;
}

.card:hover::before {
  background-position: 0% 0%;
}

.card > img {
  width: min(90vh, 30vw);
  aspect-ratio: 1;
  object-fit: cover;
}

.card > :first-child {
  mix-blend-mode: multiply;
  position: absolute;
  filter: brightness(0%);
  transition: filter 0.5s ease;
}

.card:hover > :first-child {
  filter: brightness(100%);
}

.card > :nth-child(2) {
  mix-blend-mode: plus-lighter;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.content h1 {
  font-size: 8rem;
  font-weight: 600;
  -webkit-text-stroke: 2px rgb(168, 239, 255, 1);
  color: transparent;
  transition: all 0.5s ease;
  margin: 0;
}
.content h2 {
  font-size: 4.4rem;
  font-weight: 600;
  -webkit-text-stroke: 2px rgb(168, 239, 255, 1);
  color: transparent;
  transition: all 0.5s ease;
  margin-top: 0;
}

.btn {
  width: 180px;
  height: 50px;
  border-radius: 5px;
  background: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s ease-in;
}

.btn:hover {
  transform: translateY(-5px);
}

.light::before {
  content: "";
  position: absolute;
  background-image: conic-gradient(#a8efff 20deg, transparent 150deg);
  width: 400%;
  height: 400%;
  border-radius: 5px;
  animation: rotate 3s linear infinite;
}

.light::after {
  content: "Inquiry";
  position: absolute;
  width: 170px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(35px);
  -webkit-backdrop-filter: blur(35px);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  border-radius: 5px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.background-video {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media (min-aspect-ratio: 16/9) {
  .background-video {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 4/3) {
  .background-video {
    width: auto;
    height: 100%;
  }
}
</style>
