<template>
  <div>
    <header>
      <router-link to="/">
        <img src="@/assets/Impulse-logo.png" alt="logo" class="logo" />
      </router-link>

      <input type="checkbox" id="check" />
      <label for="check" class="icons">
        <i class="bx bx-menu" id="menu-icon"></i>
        <i class="bx bx-x" id="close-icon"></i>
      </label>
      <nav class="navbar">
        <router-link class="nav-item" :style="{ '--i': 0 }" to="/">
          About
        </router-link>
        <router-link class="nav-item" :style="{ '--i': 1 }" to="/inquiry">
          Inquiry
        </router-link>
        <router-link class="nav-item" :style="{ '--i': 2 }" to="/amenities">
          Amenities
        </router-link>
      </nav>
    </header>
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

header {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 0.2rem 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  /* background: linear-gradient(rgba(12, 3, 51, 0.3), rgba(12, 3, 51, 0.3)); */
}

.logo {
  width: 70px;
}

.nav-item {
  position: relative;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.13px;
  text-decoration: none;
  margin-left: 2.5rem;
  transition: all 0.5s ease;
}

.nav-item:hover {
  color: rgb(168, 239, 255, 0.9);
}

.nav-item::after {
  content: "";
  position: absolute;
  bottom: -0.3rem;
  left: 50%;
  width: 0;
  height: 0.15rem;
  transform: translateX(-50%);
  background-color: rgb(168, 239, 255, 0.9);
  transition: all 0.5s ease;
}

.nav-item:hover::after {
  width: 100%;
}

.icons {
  position: absolute;
  right: 5%;
  font-size: 2.3rem;
  color: #fff;
  cursor: pointer;
  display: none;
}

#check {
  display: none;
}

@media (max-width: 900px) {
  header {
    padding: 1.3rem 5%;
  }

  .content h1 {
    font-size: 7rem;
  }
}

@media (max-width: 700px) {
  header::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(50px);
    z-index: -1;
  }

  .icons {
    display: inline-flex;
  }

  #check:checked ~ .icons #menu-icon {
    display: none;
  }

  .icons #close-icon {
    display: none;
  }

  #check:checked ~ .icons #close-icon {
    display: block;
  }

  .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(50px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
  }

  #check:checked ~ .navbar {
    height: 17.7rem;
  }

  .nav-item {
    display: block;
    font-size: 1.1rem;
    margin: 2rem 0;
    text-align: center;
    transform: translateY(-50px);
    opacity: 0;
    transition: all 0.3s ease;
  }

  .nav-item:hover::after {
    width: auto;
  }

  #check:checked ~ .navbar a {
    transform: translateY(0);
    opacity: 1;
    transition-delay: calc(0.15s * var(--i));
  }
}

@media (max-width: 620px) {
  .content h1 {
    font-size: 5rem;
  }
}

@media (max-width: 450px) {
  .content h1 {
    font-size: 3rem;
  }

  .btn {
    width: 130px;
  }

  .light::after {
    width: 120px;
    font-size: 0.9rem;
  }
}
</style>
